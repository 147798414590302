(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/modules/divider/assets/javascripts/divider-v3.js') >= 0) return;  svs.modules.push('/modules/divider/assets/javascripts/divider-v3.js');
"use strict";


svs = svs || {};
svs.content = svs.content || {};
svs.content.divider = svs.content.divider || {};
svs.content.divider.V3 = function () {
  const initZDegree = () => "".concat(Number(gsap.utils.random(20, 90)) % 2 ? '-' : '').concat(Number(gsap.utils.random(15, 90)), "deg");
  const randomPositiveZDegree = () => "".concat(Number(gsap.utils.random(0, 30)) % 2 ? '+' : '').concat(Number(gsap.utils.random(0, 30)), "deg");
  const randomNegativeZDegree = () => "".concat(Number(gsap.utils.random(0, 25)) % 2 ? '+' : '').concat(Number(gsap.utils.random(0, 10)), "deg");
  let sport, tur, sportBrands, turBrands, vw, brandWidth;
  const init = () => {
    var _window;
    sport = document.querySelector('.divider-hero-sport');
    tur = document.querySelector('.divider-hero-tur');
    sportBrands = sport.querySelectorAll('.divider-v2-brand');
    turBrands = tur.querySelectorAll('.divider-v2-brand');
    vw = (_window = window) === null || _window === void 0 ? void 0 : _window.innerWidth;
    brandWidth = turBrands[0].clientWidth * 1.5;
    const isReduced = window.matchMedia("(prefers-reduced-motion: reduce)") === true || window.matchMedia("(prefers-reduced-motion: reduce)").matches === true;
    if (!isReduced) {
      gsap.set([turBrands[0]], {
        y: 10,
        x: brandWidth,
        opacity: 1,
        scale: 1,
        rotationZ: initZDegree
      });
      gsap.set([turBrands[1]], {
        y: -10,
        x: brandWidth,
        opacity: 1,
        scale: 1,
        rotationZ: initZDegree
      });
      gsap.set([sportBrands[0]], {
        y: -20,
        x: -brandWidth,
        opacity: 1,
        scale: 1,
        rotationZ: initZDegree
      });
      gsap.set([sportBrands[1]], {
        y: -10,
        x: -brandWidth,
        opacity: 1,
        scale: 1,
        rotationZ: initZDegree
      });
      gsap.set([sportBrands[2]], {
        y: vw > 1024 ? -10 : 200,
        x: -brandWidth * 1.5,
        opacity: 1,
        scale: 1,
        rotationZ: initZDegree
      });
      const tl = gsap.timeline();
      tl.to([sportBrands], {
        x: 0,
        y: 0,
        rotate: randomPositiveZDegree(),
        opacity: 1,
        duration: 4.4,
        delay: 0.3,
        scale: 1,
        ease: 'back.out(1.3)',
        stagger: 0.3
      }).to([turBrands], {
        x: 0,
        y: 0,
        rotate: randomNegativeZDegree(),
        scale: 1,
        ease: 'back.out(1.4)',
        opacity: 1,
        duration: 4.9,
        stagger: 0.4
      }, '<');
    } else {
      const tl = gsap.timeline();
      tl.to([sportBrands], {
        x: 0,
        y: 0,
        rotate: 0,
        opacity: 1,
        duration: 1.5,
        delay: 0,
        scale: 1,
        ease: 'back.out(1)',
        stagger: 0
      }).to([turBrands], {
        x: 0,
        y: 0,
        rotate: 0,
        scale: 1,
        opacity: 1,
        ease: 'back.out(1)',
        duration: 1.5,
        stagger: 0
      }, '<');
    }
  };
  return {
    init
  };
};

 })(window);
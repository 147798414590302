(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/modules/divider/views/v3.js') >= 0) return;  svs.modules.push('/modules/divider/views/v3.js');
(function(svs, Handlebars) {
_svs=window.svs=window.svs||{};
_svs.divider=_svs.divider||{};
_svs.divider.templates=_svs.divider.templates||{};
svs.divider.templates.v3 = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    return "  <script>\n    // Skip animation if user comes back.\n    function pageShown(){\n      const perfEntries = performance.getEntriesByType('navigation');\n      if (perfEntries.length && perfEntries[0].type === 'back_forward') {\n        document.querySelector('.js-divider-header-section').classList.add('divider-skip-animations');\n      }\n    }\n    window.addEventListener(\"pageshow\", pageShown, false);\n  </script>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(((helper = (helper = lookupProperty(helpers,"heroText") || (depth0 != null ? lookupProperty(depth0,"heroText") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"heroText","hash":{},"data":data,"loc":{"start":{"line":31,"column":26},"end":{"line":31,"column":38}}}) : helper)));
},"5":function(container,depth0,helpers,partials,data) {
    return "Välkommen till hela Sveriges spelbolag!";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "          <div class='divider-ao-small-image-holder'>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"sportImageAo") : depth0),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":38,"column":12},"end":{"line":47,"column":19}}})) != null ? stack1 : "")
    + "          </div>\n          <img class=\"divider-ao-info-image\" src="
    + alias3((lookupProperty(helpers,"getAssetUrl")||(depth0 && lookupProperty(depth0,"getAssetUrl"))||alias2).call(alias1,"/images/components/ao-logotype-soc/ao-soc.svg",{"name":"getAssetUrl","hash":{},"data":data,"loc":{"start":{"line":49,"column":49},"end":{"line":49,"column":112}}}))
    + " alt=\"Svenska Spel Sport och Casino\" />\n          <div class=\"divider-link-text\">\n            <h2>Sport- och casinospel</h2>\n            "
    + alias3((lookupProperty(helpers,"ui")||(depth0 && lookupProperty(depth0,"ui"))||alias2).call(alias1,"icon",{"name":"ui","hash":{"icon":"arrow-right","size":"200"},"data":data,"loc":{"start":{"line":52,"column":12},"end":{"line":52,"column":55}}}))
    + "\n          </div>\n";
},"8":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = container.invokePartial(lookupProperty(partials,"components-content-image_v2-multi_image"),depth0,{"name":"components-content-image_v2-multi_image","hash":{"fitWrapper":false,"loadingLazy":false,"cssSelectors":"divider-games-section-ao-image","title":"Sport- och casinospel","altText":((stack1 = (depth0 != null ? lookupProperty(depth0,"sportImageAo") : depth0)) != null ? lookupProperty(stack1,"altText") : stack1),"images":(lookupProperty(helpers,"array")||(depth0 && lookupProperty(depth0,"array"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"sportImageAo") : depth0),(depth0 != null ? lookupProperty(depth0,"sportImageAoSmall") : depth0),{"name":"array","hash":{},"data":data,"loc":{"start":{"line":40,"column":23},"end":{"line":40,"column":61}}})},"data":data,"indent":"              ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"10":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <a href=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"url") : depth0), depth0))
    + "\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"target") : depth0),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":57,"column":35},"end":{"line":57,"column":75}}})) != null ? stack1 : "")
    + " class=\"divider-brand-link bg-"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"name") : depth0), depth0))
    + " brand-"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"name") : depth0), depth0))
    + " elevation-100 elevation-auto-raise brand-rectangle js-divider-link\" data-area-name=\"\" data-track="
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"dataTrack") : depth0), depth0))
    + ">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"hasRibbon") : depth0),{"name":"if","hash":{},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":58,"column":14},"end":{"line":60,"column":21}}})) != null ? stack1 : "")
    + "              "
    + alias2((lookupProperty(helpers,"ui")||(depth0 && lookupProperty(depth0,"ui"))||container.hooks.helperMissing).call(alias3,"brands",{"name":"ui","hash":{"emblem":true,"size":(depth0 != null ? lookupProperty(depth0,"brandSize") : depth0),"outline":(depth0 != null ? lookupProperty(depth0,"outline") : depth0),"brand":(depth0 != null ? lookupProperty(depth0,"brand") : depth0),"class":"divider-brand"},"data":data,"loc":{"start":{"line":61,"column":14},"end":{"line":61,"column":121}}}))
    + "\n            </a>\n";
},"11":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "target=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"target") || (depth0 != null ? lookupProperty(depth0,"target") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"target","hash":{},"data":data,"loc":{"start":{"line":57,"column":57},"end":{"line":57,"column":67}}}) : helper)))
    + "\"";
},"13":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                "
    + ((stack1 = (lookupProperty(helpers,"ui")||(depth0 && lookupProperty(depth0,"ui"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"ribbon/banner",{"name":"ui","hash":{"offsetTop":-3},"fn":container.program(14, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":59,"column":16},"end":{"line":59,"column":78}}})) != null ? stack1 : "")
    + "\n";
},"14":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"ribbonText") : depth0), depth0));
},"16":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "          <div class=\"divider-ao-small-image-holder\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"turImageAo") : depth0),{"name":"if","hash":{},"fn":container.program(17, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":73,"column":12},"end":{"line":82,"column":19}}})) != null ? stack1 : "")
    + "          </div>\n          <img class=\"divider-ao-info-image\" src="
    + alias3((lookupProperty(helpers,"getAssetUrl")||(depth0 && lookupProperty(depth0,"getAssetUrl"))||alias2).call(alias1,"/images/components/ao-logotype-tur/ao-tur.svg",{"name":"getAssetUrl","hash":{},"data":data,"loc":{"start":{"line":84,"column":49},"end":{"line":84,"column":112}}}))
    + " alt='Svenska Spel Tur'/>\n          <div class=\"divider-link-text\">\n            <h2>Turspel</h2>\n            "
    + alias3((lookupProperty(helpers,"ui")||(depth0 && lookupProperty(depth0,"ui"))||alias2).call(alias1,"icon",{"name":"ui","hash":{"icon":"arrow-right","size":"200"},"data":data,"loc":{"start":{"line":87,"column":12},"end":{"line":87,"column":55}}}))
    + "\n          </div>\n";
},"17":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = container.invokePartial(lookupProperty(partials,"components-content-image_v2-multi_image"),depth0,{"name":"components-content-image_v2-multi_image","hash":{"fitWrapper":false,"loadingLazy":false,"cssSelectors":"divider-games-section-ao-image","title":"Turspel","altText":((stack1 = (depth0 != null ? lookupProperty(depth0,"turImageAo") : depth0)) != null ? lookupProperty(stack1,"altText") : stack1),"images":(lookupProperty(helpers,"array")||(depth0 && lookupProperty(depth0,"array"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"turImageAo") : depth0),(depth0 != null ? lookupProperty(depth0,"turImageAoSmall") : depth0),{"name":"array","hash":{},"data":data,"loc":{"start":{"line":75,"column":23},"end":{"line":75,"column":57}}})},"data":data,"indent":"              ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"19":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <a href='"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"url") : depth0), depth0))
    + "' class=\"divider-brand-link bg-"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"name") : depth0), depth0))
    + " elevation-100 elevation-auto-raise brand-rectangle js-divider-link\" data-area-name=\"\" data-track="
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"dataTrack") : depth0), depth0))
    + ">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"hasRibbon") : depth0),{"name":"if","hash":{},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":93,"column":14},"end":{"line":95,"column":21}}})) != null ? stack1 : "")
    + "              "
    + alias2((lookupProperty(helpers,"ui")||(depth0 && lookupProperty(depth0,"ui"))||container.hooks.helperMissing).call(alias3,"brands",{"name":"ui","hash":{"emblem":true,"outline":(depth0 != null ? lookupProperty(depth0,"outline") : depth0),"size":(depth0 != null ? lookupProperty(depth0,"brandSize") : depth0),"brand":(depth0 != null ? lookupProperty(depth0,"brand") : depth0),"class":"divider-brand"},"data":data,"loc":{"start":{"line":96,"column":14},"end":{"line":96,"column":121}}}))
    + "\n            </a>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = (lookupProperty(helpers,"extend")||(depth0 && lookupProperty(depth0,"extend"))||alias2).call(alias1,"body-prepend",{"name":"extend","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":12,"column":11}}})) != null ? stack1 : "")
    + "\n<div class='divider-header-section js-divider-header-section'>\n  <div class='divider-hero'>\n    <div class='divider-hero-sport'>\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"svs-divider-brand"),depth0,{"name":"svs-divider-brand","hash":{"img":"/images/divider/brands/stryktipset"},"data":data,"indent":"      ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + ((stack1 = container.invokePartial(lookupProperty(partials,"svs-divider-brand"),depth0,{"name":"svs-divider-brand","hash":{"img":"/images/divider/brands/oddset"},"data":data,"indent":"      ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + ((stack1 = container.invokePartial(lookupProperty(partials,"svs-divider-brand"),depth0,{"name":"svs-divider-brand","hash":{"img":"/images/divider/brands/vinnare"},"data":data,"indent":"      ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "    </div>\n    <div class='divider-hero-tur'>\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"svs-divider-brand"),depth0,{"name":"svs-divider-brand","hash":{"img":"/images/divider/brands/lotto"},"data":data,"indent":"      ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + ((stack1 = container.invokePartial(lookupProperty(partials,"svs-divider-brand"),depth0,{"name":"svs-divider-brand","hash":{"img":"/images/divider/brands/triss"},"data":data,"indent":"      ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "    </div>\n  </div>\n  <div class='divider-header-brand divider-svs-brand'>\n    "
    + container.escapeExpression((lookupProperty(helpers,"ui")||(depth0 && lookupProperty(depth0,"ui"))||alias2).call(alias1,"brands",{"name":"ui","hash":{"emblem":true,"brand":"svenskaspel-twolines-neg"},"data":data,"loc":{"start":{"line":27,"column":4},"end":{"line":27,"column":64}}}))
    + "\n  </div>\n  <div class='divider-games-section'>\n    <div class='divider-header-content'>\n      <h1>"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"heroText") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.program(5, data, 0),"data":data,"loc":{"start":{"line":31,"column":10},"end":{"line":31,"column":92}}})) != null ? stack1 : "")
    + "</h1>\n      <p>Välj mellan våra två sajter.</p>\n    </div>\n    <div class='divider-games-column-perspective'>\n      <div class='divider-games-column divider-games-column-sport u-border-radius-12 elevation-100 bg-sport-100'>\n"
    + ((stack1 = (lookupProperty(helpers,"ui")||(depth0 && lookupProperty(depth0,"ui"))||alias2).call(alias1,"paper",{"name":"ui","hash":{"data-track":"divider-ao-sport","data-area-name":"","href":((stack1 = (depth0 != null ? lookupProperty(depth0,"siteUrls") : depth0)) != null ? lookupProperty(stack1,"sport") : stack1),"class":"divider-games-section-ao-info elevation-100-filter js-divider-link bg-sport","isFlat":true},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":36,"column":8},"end":{"line":54,"column":15}}})) != null ? stack1 : "")
    + "        <div class=\"divider-games-list\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"brands") : depth0)) != null ? lookupProperty(stack1,"sport") : stack1),{"name":"each","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":56,"column":10},"end":{"line":63,"column":19}}})) != null ? stack1 : "")
    + "        </div>\n        <p class=\"divider-games-from-text\">Spel från Svenska Spel<br> Sport & Casino AB</p>\n      </div>\n    </div>\n\n    <div class=\"divider-games-column-perspective\">\n      <div class=\"divider-games-column divider-games-column-tur u-border-radius-12 elevation-100 bg-tur-100\">\n"
    + ((stack1 = (lookupProperty(helpers,"ui")||(depth0 && lookupProperty(depth0,"ui"))||alias2).call(alias1,"paper",{"name":"ui","hash":{"data-track":"divider-ao-tur","data-area-name":"","href":((stack1 = (depth0 != null ? lookupProperty(depth0,"siteUrls") : depth0)) != null ? lookupProperty(stack1,"tur") : stack1),"class":"paper divider-games-section-ao-info elevation-100-filter js-divider-link paper-neutral paper-link elevation-auto-raise","isFlat":true},"fn":container.program(16, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":71,"column":8},"end":{"line":89,"column":15}}})) != null ? stack1 : "")
    + "        <div class=\"divider-games-list\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"brands") : depth0)) != null ? lookupProperty(stack1,"tur") : stack1),{"name":"each","hash":{},"fn":container.program(19, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":91,"column":10},"end":{"line":98,"column":19}}})) != null ? stack1 : "")
    + "        </div>\n        <p class=\"divider-games-from-text\">Spel från AB Svenska Spel</p>\n      </div>\n    </div>\n  </div>\n</div>\n";
},"usePartial":true,"useData":true});
Handlebars.partials['svs-divider-v3'] = svs.divider.templates.v3;
})(svs, Handlebars);


 })(window);